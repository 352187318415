import { CreateControllerFn, IHttpClient } from '@wix/yoshi-flow-editor';

const getAdaptiveContent = async (
  originalContent: string,
  componentId: string,
  $w: any,
  httpClient: IHttpClient,
) => {
  const { data: newContent } = await httpClient.post<any>(
    '/_api/adaptive-component/v1/adaptive/content',
    {
      original_content: originalContent,
      componentId,
    },
    {
      headers: {
        'X-Time-Budget': 10000,
      },
    },
  );
  $w('@text').text = newContent.content.content;
};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
  appData,
  widgetConfig,
}) => {
  const $w = controllerConfig.$w as any;

  return {
    pageReady: async (page) => {
      if (flowAPI.environment.isSSR || flowAPI.environment.isPreview) {
        const originalContent = $w('@text').text;
        await getAdaptiveContent(
          originalContent,
          controllerConfig.compId,
          $w,
          flowAPI.httpClient,
        );
      }
    },
  };
};

export default createController;
